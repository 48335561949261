import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BEMHelper from "react-bem-helper";
import SwiftType from "../components/swift-type";

import Img from "gatsby-image/withIEPolyfill";
// Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderImageVideo from "../modules/headers/header-image-video";
import HeaderSolidColor from "../modules/headers/header-solid-color";
import moduleConductor from "../modules/module-conductor";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MobileMenuArrow from "../blocks/mobile-menu-arrow";

// Helpers, Functions
import { richTextConfig } from "../rich-text-config/rich-text-config";

const bem = new BEMHelper({
  name: "template-product",
});

const TemplateProduct = ({ data, location, pageContext }) => {
  const [selectedInfo, setSelectedInfo] = useState("description");
  const productData = data.allContentfulTemplateTeardownReport.edges[0].node;
  const ctaBanner = data.ctaBanner ? data.ctaBanner.nodes[0] : null;
  // console.log(productData);
  const {
    title,
    seoPageTitle,
    headerDescription,
    listingDescription,
    productDescription,
    technicalInfo,
    productImage,
    mainImage,
    price,
    relatedSolution,
    publishDate,
    swiftDate,
    slug,
  } = productData;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const metaTitle = seoPageTitle || title;
  return (
    <Layout isWhiteNav={true} location={location}>
      <SEO
        title={`${metaTitle} | Teardown Reports | UnitedLex`}
        description={
          listingDescription?.listingDescription ||
          headerDescription?.headerDescription
        }
        url={location?.href}
        image={mainImage?.file?.url || productImage?.file?.url}
      />
      <SwiftType
        customTitle={title}
        customDesc={listingDescription?.listingDescription}
        customFeatImg={productImage?.file?.url || mainImage?.file?.url}
        tags={["teardown"]}
        contentTypes={[
          "Teardown Report",
          relatedSolution.pageTitle,
          "teardown",
        ]}
        customPublishDate={new Date(swiftDate).getTime() / 1000}
        solutions={[relatedSolution?.pageTitle]}
      />
      <div {...bem()}>
        <div {...bem("body")}>
          <div {...bem("header")}>
            <div {...bem("header-img")}>
              <div className="header-img-wrap">
                {productImage && <GatsbyImage image={getImage(productImage.gatsbyImageData)} alt={productImage.description || ""} title={productImage.description || ""} />}
              </div>
            </div>
            <div {...bem("header-content")}>
              <p>Teardown Report</p>
              <h1>{title}</h1>
              <p style={{ paddingBottom: "24px" }}>
                {listingDescription?.listingDescription}
              </p>
              <h2>{"$" + price + " USD"}</h2>
              <Link {...bem("purchase-link")} href={"/contact"}>
                Contact Us for Purchase
              </Link>
              <p style={{ paddingTop: "24px" }}>
                {headerDescription?.headerDescription}
              </p>
            </div>
          </div>
          <div {...bem("content-tabs")}>
            <ul>
              <li
                onClick={() => setSelectedInfo("description")}
                className={selectedInfo === "description" ? "highlighted" : ""}
              >
                Description
              </li>
              <li
                onClick={() => setSelectedInfo("technical")}
                className={selectedInfo === "technical" ? "highlighted" : ""}
              >
                Technical Info
              </li>
            </ul>
          </div>
          <div {...bem("content-wrap")}>
            <div {...bem("content-body")}>
              {selectedInfo === "description" && (
                <React.Fragment>
                  <h2>Description</h2>
                  {documentToReactComponents(
                    JSON.parse(productDescription.raw),
                    richTextConfig()
                  )}
                </React.Fragment>
              )}
              {selectedInfo === "technical" && (
                <React.Fragment>
                  <h2>Technical Info</h2>
                  {documentToReactComponents(
                     JSON.parse(technicalInfo.raw),
                    richTextConfig()
                  )}
                </React.Fragment>
              )}
            </div>

            <article {...bem("event-content")}>
              {relatedSolution && (
                <div {...bem("aside-section")}>
                  <h4 {...bem("aside-section-header")}>Related Solutions</h4>

                  <div {...bem("related-solution")}>
                    <h4>{relatedSolution.pageTitle}</h4>
                    <Link to={`/solutions/${relatedSolution.slug}`}>
                      View
                      <MobileMenuArrow />
                    </Link>
                  </div>
                  {relatedSolution.solutionImg && (
                    <GatsbyImage
                      {...bem("teaser-image")}
                      // fixed={relatedSolution.solutionImg}
                      image={getImage(relatedSolution.gatsbyImageData)} 
                      alt={relatedSolution.solutionImg.description || ""}
                      title={relatedSolution.solutionImg.description || ""}
                    />
                  )}
                </div>
              )}
            </article>
          </div>
          <div {...bem("more-products")}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "60px",
              }}
            >
              <h4 {...bem("more-products-headline")}>More Teardown Reports</h4>
              <Link to={"../"}>See all Teardown Reports</Link>
            </div>
            {pageContext.otherProducts && (
              <React.Fragment>
                <div {...bem("more-products-list")}>
                  {pageContext.otherProducts &&
                    pageContext.otherProducts.map((product, i) => {
                      return (
                        <div {...bem("product-card")}>
                          <Link
                            to={`../${product.slug}`}
                            {...bem("product-card-flex")}
                          >
                            <div {...bem("product-img-wrap")}>
                              <img
                                src={product.productImage.file.url}
                                {...bem("product-img")}
                                alt={product.productImage.description || ""}
                                title={product.productImage.description || ""}
                              />
                            </div>
                            {/* <Img
                        // fluid={mainImage.fluid}
                        // className="featured-insight__img"
                        {...bem("product-img")}
                        fluid={product.productImage.fluid}
                        // imgStyle={{ width: "100px" }}
                        // objectFit="cover"
                        // objectPosition="50% 50%"
                      /> */}
                            {product.reportType && (
                              <p {...bem("product-label")}>
                                {product.reportType}
                              </p>
                            )}
                            {product.title && (
                              <h2 {...bem("product-title")}>{product.title}</h2>
                            )}
                            {product.listingDescription && (
                              <p {...bem("product-desc")}>
                                {product.listingDescription.listingDescription}
                              </p>
                            )}
                            {product.price && (
                              <p {...bem("product-price")}>
                                ${product.price} USD
                              </p>
                            )}
                            <button {...bem("product-button")}>
                              Contact Us for Purchase
                            </button>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </React.Fragment>
            )}
            {/* <MoreReports>
              {Array.from({ length: 4 }).map((_) => {
                return (
                  <ReportPreview>
                    {productImage && <Img fluid={productImage?.fluid} />}
                    <p>Teardown Report</p>
                    <h3>Praesant Gravida Velit Non Ligula Dignissim</h3>
                    <p>
                      Praesant Gravida Velit Non Ligula Dignissim. Praesant
                      Gravida Velit Non Ligula Dignissim.
                    </p>
                  </ReportPreview>
                );
              })}
            </MoreReports> */}
          </div>

          {ctaBanner && moduleConductor(ctaBanner, 0)}
          {/* <ConversionPanel>
            <h2>Need a special report or have questions for us?</h2>
            <p>Contact us if you need a special set of reports.</p>
            <button>Contact us</button>
          </ConversionPanel> */}
        </div>
      </div>
    </Layout>
  );
};

export default TemplateProduct;

export const pageQuery = graphql`
  query($slug: String!, $ctaBannerId: String!) {
    allContentfulTemplateTeardownReport(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          seoPageTitle
          slug
          doNotIndex
          price
          reportType
          publishDate(formatString: "MMMM D, YYYY")
          swiftDate: publishDate(formatString: "YYYY.MM.DD")
          headerDescription {
            headerDescription
          }
          listingDescription {
            listingDescription
          }
          productDescription {
            raw
          }
          technicalInfo {
            raw
          }
          productImage {
            description
            gatsbyImageData
            # fluid(maxWidth: 800, quality: 80) {
            #  ...GatsbyContentfulFluid
            # }
            file {
              url
            }
          }
          relatedSolution {
            slug
            pageTitle
            header {
              ... on Node {
                ... on ContentfulModuleHeaderWithMedia {
                  __typename
                  headerImage {
                    description
                    gatsbyImageData(
                      layout: FIXED,
                      formats: JPG,
                      width: 120,
                      height: 200
                      cropFocus: CENTER
                      resizingBehavior: FILL
                    )
                    # fixed(
                    #  toFormat: JPG
                    #  width: 120
                    #  height: 200
                    #  cropFocus: CENTER
                    #  resizingBehavior: FILL
                    # ) {
                    #  ...GatsbyContentfulFixed
                    # }
                  }
                }
              }
            }
          }
        }
      }
    }
    ctaBanner: allContentfulModuleCtaBanner(
      filter: { contentful_id: { eq: $ctaBannerId } }
    ) {
      nodes {
        ...ModuleCtaBannerFragment
      }
    }
  }
`;

const MoreReports = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ReportPreview = styled.div`
  width: 100%;
  padding: 16px;
`;

const ConversionPanel = styled.div`
  padding: 48px;
  background-color: #e91b47;
  color: white;
  h2,
  p {
    max-width: 320px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
  }
  button {
    cursor: pointer;
    margin-top: 32px;
    background-color: #211748;
    color: white;
    padding: 12px 32px;
    &:hover {
      opacity: 0.8;
    }
  }
`;
